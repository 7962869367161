export default {
  "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
  "messages": {
    "_default": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("field")), " is not valid"])},
    "alpha_spaces": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("field")), " field may only contain alphabetic characters as well as spaces"])},
    "country_excluded:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to restrictions, we do not accept users from this location."])},
    "email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("field")), " field must be a valid email"])},
    "excluded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("field")), " field is not a valid value"])},
    "max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("field")), " field may not be greater than 0:", _interpolate(_named("length")), " characters"])},
    "max_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("field")), " field must be 0:", _interpolate(_named("max")), " or less"])},
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("field")), " field must be at least 0:", _interpolate(_named("length")), " characters"])},
    "min_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("field")), " field must be 0:", _interpolate(_named("min")), " or more"])},
    "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passwords don't match"])},
    "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("field")), " field is required"])},
    "tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the Term of Service."])}
  },
  "song": {
    "alert_message_requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "alert_message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  }
}