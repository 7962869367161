import axios from 'axios';
// eslint-disable-next-line object-curly-newline
import { get, post, put, del } from './network';

// Auth
export const createUser = async (p) => post('create_user', p);
export const loginWithEmail = async (p) => post('login_with_email', p);
export const updateProfile = async (p) => put('update_profile', p);
export const updatePassword = async (p) => put('update_password', p);
export const getProfile = (p) => get('get_profile', p);
export const getPermission = async (p) => get('get_permission', p);

// songs
// eslint-disable-next-line arrow-body-style
export const createSong = async (p) => {
  return new Promise((resolve, reject) => {
    axios
      .post('create_song', p, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
export const deleteSong = async (p) => del('delete_song', p);
export const editSong = async (p) => put('edit_song', p);
export const getSongs = (p) => get('get_songs', p);

// comments
export const getComments = (p) => get('get_comments', p);
export const editComment = async (p) => put('edit_comment', p);
export const deleteComment = async (p) => del('delete_comment', p);
export const addComment = async (p) => post('add_comment', p);
