/* eslint-disable no-unused-expressions */
// 在http.js中引入axios
import axios from 'axios';
import QS from 'qs';
import store from '@/store/index';
// import router from '@/router/index';
// import { ElNotification } from 'element-plus';

if (process.env.NODE_ENV === 'development') {
  // 开发环境
  axios.defaults.baseURL = 'https://backend.pumgking.top/pkmusic/api/v1/';
} else if (process.env.NODE_ENV === 'production') {
  // 生产环境
  axios.defaults.baseURL = 'https://backend.pumgking.top/pkmusic/api/v1/';
}

axios.defaults.timeout = 10000;

const defaultHeader = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.post['Content-Type'] = defaultHeader;

const checkHeader = (response) => {
  if (JSON.stringify(response.data.header) !== '{}') {
    localStorage.setItem('PK_token', response.data.header.PK_token);
    localStorage.setItem('PK_refresh', response.data.header.PK_refresh);
  }
};

axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = localStorage.getItem('PK_token');
    // eslint-disable-next-line no-param-reassign
    token !== null && (config.headers.authorization = token);
    // eslint-disable-next-line no-param-reassign
    config.cancelToken = new axios.CancelToken((c) => {
      store.dispatch('setCancelAxios', c);
    });
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.status === 200) {
      checkHeader(response);
      return Promise.resolve(response);
    }
    checkHeader(response);
    return Promise.reject(response);
  },
  // 服务器状态码不是2开头的的情况
  // 这里可以跟你们的后台开发人员协商好统一的错误状态码
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  // 下面列举几个常见的操作，其他需求可自行扩展
  (error) => Promise.reject(error.response),
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, headers) {
  console.log(params);
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params), headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, QS.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * delete方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function del(url, params) {
  const param = {
    params,
  };
  return new Promise((resolve, reject) => {
    axios
      .delete(url, param)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
