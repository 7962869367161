<template>
  <section>
    <!-- Login Form -->
    <div
      class="text-white text-center font-bold p-4 mb-4"
      v-if="login_show_alert"
      :class="login_alert_variant"
    >
      {{ login_alert_msg }}
    </div>
    <vee-form :validation-schema="loginSchema" @submit="login">
      <!-- Email -->
      <div class="mb-3">
        <label class="inline-block mb-2">{{ $t('login.email') }}</label>
        <vee-field
          type="email"
          :name="getEmail"
          v-model="info.email"
          class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition duration-500 focus:outline-none focus:border-black rounded"
        />
        <ErrorMessage class="text-red-600" :name="getEmail" />
      </div>
      <!-- Password -->
      <div class="mb-3">
        <label class="inline-block mb-2">{{ $t('login.password') }}</label>
        <vee-field
          type="password"
          :name="getPassword"
          v-model="info.password"
          class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition duration-500 focus:outline-none focus:border-black rounded"
        />
        <ErrorMessage class="text-red-600" :name="getPassword" />
      </div>
      <button
        type="submit"
        @click="login(info)"
        :disabled="login_in_submission"
        class="block w-full text-white py-1.5 px-3 rounded transition"
        :class="login_submit_variant"
      >
        {{ $t('login.button') }}
      </button>
    </vee-form>
  </section>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      loginSchema: {
        email: 'required|email',
        password: 'required|min:3|max:32',
        电子邮箱: 'required|email',
        密码: 'required|min:3|max:32',
        'Электронное письмо': 'required|email',
        Пароль: 'required|min:3|max:32',
      },
      login_in_submission: false,
      login_show_alert: false,
      login_alert_variant: 'bg-blue-500',
      login_submit_variant: 'bg-purple-600 hover:bg-purple-700',
      login_alert_msg: this.$t('login.alert_message_requesting'),
      info: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    getEmail() {
      return this.$t('login.email');
    },
    getPassword() {
      return this.$t('login.password');
    },
  },
  methods: {
    async login(values) {
      this.login_in_submission = true;
      this.login_show_alert = true;
      this.reg_submit_variant = 'bg-gray-600';
      this.login_alert_variant = 'bg-blue-500';
      this.login_alert_msg = this.$t('login.alert_message_requesting');

      try {
        await this.$store.dispatch('login', values);
      } catch (error) {
        this.login_in_submission = false;
        this.login_alert_variant = 'bg-red-500';
        this.login_submit_variant = 'bg-purple-600 hover:bg-purple-700';
        this.login_alert_msg = this.$t('login.alert_message_fail');
        return;
      }

      this.login_alert_variant = 'bg-green-500';
      this.login_alert_msg = this.$t('login.alert_message_success');

      window.location.reload();
    },
  },
};
</script>
