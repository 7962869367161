// eslint-disable-next-line object-curly-newline
import { sha256 } from 'js-sha256';
import {
  loginWithEmail,
  createUser,
  getProfile,
  updateProfile,
  updatePassword,
} from '@/api';

export default {
  // namespaced: true,
  state: {
    authModalShow: false,
    userLoggedIn: false,
    userInfoChange: false,
    isInHomePage: false,
  },
  mutations: {
    toggleAuthModal: (state) => {
      state.authModalShow = !state.authModalShow;
    },
    toggleAuth(state) {
      state.userLoggedIn = !state.userLoggedIn;
    },
    toggleUserInfo(state) {
      state.userInfoChange = !state.userInfoChange;
    },
    logout(state) {
      state.userLoggedIn = false;
    },
    toggleHomepage(state) {
      state.isInHomePage = !state.isInHomePage;
    },
  },
  actions: {
    async register({ commit }, payload) {
      const param = {
        email: payload.email,
        name: payload.name,
        age: payload.age,
        password: sha256(payload.password),
        country: payload.country,
      };
      await createUser(param);

      commit('toggleAuth');
    },
    async login({ commit }, payload) {
      const params = {
        email: payload.email,
        password: sha256(payload.password),
      };
      // Promise
      await loginWithEmail(params).then(() => {
        commit('toggleAuth');
      });
    },
    init_login({ commit }) {
      const token = localStorage.getItem('PK_token');
      if (token !== null) {
        getProfile().then(() => {
          commit('toggleAuth');
        });
      }
      getProfile().then((resolve) => {
        localStorage.setItem(
          'userPermission',
          resolve.response.profile[0].permission,
        );
      });
    },
    signout({ commit }) {
      commit('logout');
    },
    async editUserInfo({ commit }, payload) {
      commit('toggleUserInfo');
      await updateProfile(payload)
        .then(() => {
          commit('toggleUserInfo');
        })
        .catch(() => {
          commit('toggleUserInfo');
        });
    },
    async updatePassword({ commit }, payload) {
      commit('toggleUserInfo');
      const params = {
        password: sha256(payload.password),
      };
      await updatePassword(params);
    },
  },
};
