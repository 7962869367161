/* eslint-disable operator-linebreak */
<template>
  <section>
    <!-- Registration Form -->
    <div
      class="text-white text-center font-bold p-5 mb-4"
      v-if="reg_show_alert"
      :class="reg_alert_variant"
    >
      {{ reg_alert_msg }}
    </div>
    <vee-form
      :validation-schema="schema"
      @submit="register"
      :initial-values="userData"
    >
      <!-- Name -->
      <div class="mb-3">
        <label class="inline-block mb-2">{{ $t('register.name') }}</label>
        <vee-field
          type="text"
          :name="getName"
          v-model="userData.name"
          class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition duration-500 focus:outline-none focus:border-black rounded"
        />
        <ErrorMessage class="text-red-600" :name="getName" />
      </div>
      <!-- Email -->
      <div class="mb-3">
        <label class="inline-block mb-2">{{ $t('register.email') }}</label>
        <vee-field
          type="email"
          :name="getEmail"
          v-model="userData.email"
          class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition duration-500 focus:outline-none focus:border-black rounded"
        />
        <ErrorMessage class="text-red-600" :name="getEmail" />
      </div>
      <!-- Age -->
      <div class="mb-3">
        <label class="inline-block mb-2">{{ $t('register.age') }}</label>
        <vee-field
          type="number"
          :name="getAge"
          v-model="userData.age"
          class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition duration-500 focus:outline-none focus:border-black rounded"
        />
        <ErrorMessage class="text-red-600" :name="getAge" />
      </div>
      <!-- Password -->
      <div class="mb-3">
        <label class="inline-block mb-2">{{ $t('register.password') }}</label>
        <vee-field
          type="password"
          :name="getPassword"
          v-model="userData.password"
          class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition duration-500 focus:outline-none focus:border-black rounded"
        />
        <ErrorMessage class="text-red-600" :name="getPassword" />
        <!-- <div class="text-red-600" v-for="error in errors" :key="error">
          {{ error }}
        </div> -->
      </div>
      <!-- Confirm Password -->
      <div class="mb-3">
        <label class="inline-block mb-2">{{
          $t('register.comfirm_password')
        }}</label>
        <vee-field
          type="password"
          :name="getConfirmPassword"
          class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition duration-500 focus:outline-none focus:border-black rounded"
        />
        <ErrorMessage class="text-red-600" :name="getConfirmPassword" />
      </div>
      <!-- Country -->
      <div class="mb-3">
        <label class="inline-block mb-2">{{ $t('register.country') }}</label>
        <vee-field
          as="select"
          name="country"
          v-model="userData.country"
          class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition duration-500 focus:outline-none focus:border-black rounded"
        >
          <option value="China">中国</option>
          <option value="Russia">Россия</option>
          <option value="USA">USA</option>
          <option value="Europe">Europe</option>
          <option value="Japan">日本</option>
          <option value="Korea">대한민국</option>
          <option value="Antarctica">Antarctica</option>
        </vee-field>
        <ErrorMessage class="text-red-600" name="country" />
      </div>
      <!-- TOS -->
      <div class="mb-3 pl-6">
        <vee-field
          type="checkbox"
          name="tos"
          value="1"
          :modelValue="tos"
          class="w-4 h-4 float-left -ml-6 mt-1 rounded"
        />
        <i18n-t class="inline-block" keypath="register.accept" tag="label">
          <a href="#" @click.prevent="openTOS">{{ $t('register.TOS') }}</a>
        </i18n-t>
        <ErrorMessage class="text-red-600 block" name="tos" />
      </div>
      <button
        type="submit"
        :disabled="reg_in_submission"
        @click.prevent="register(userData)"
        class="block w-full text-white py-1.5 px-3 rounded transition"
        :class="reg_submit_variant"
      >
        {{ $t('register.button') }}
      </button>
    </vee-form>
  </section>
</template>

<script>
import { ElMessageBox } from 'element-plus';

export default {
  name: 'RegisterForm',
  data() {
    return {
      schema: {
        name: 'required|min:3|max:16|alpha_spaces',
        email: 'required|min:3|max:20|email',
        age: 'required|min_value:18|max_value:120',
        password: 'required|min:3|max:32',
        'Confirm Password': 'password_mismatch:@password',
        country: 'required|country_excluded:Antarctica',
        用户名: 'required|min:3|max:16|alpha_spaces',
        电子邮箱: 'required|min:3|max:20|email',
        年龄: 'required|min_value:18|max_value:120',
        密码: 'required|min:3|max:32',
        确认密码: 'password_mismatch:@密码',
        Ник: 'required|min:3|max:16|alpha_spaces',
        'Электронное письмо': 'required|min:3|max:20|email',
        Возраст: 'required|min_value:18|max_value:120',
        'Подтвердите пароль': 'password_mismatch:@Возраст',
        Пароль: 'required|min:3|max:32',
        tos: 'tos',
      },
      userData: {
        name: '',
        email: '',
        age: '',
        password: '',
        country: 'China',
      },
      reg_in_submission: false,
      reg_show_alert: false,
      reg_alert_variant: 'bg-blue-500',
      reg_submit_variant: 'bg-purple-600 hover:bg-purple-700',
      reg_alert_msg: this.$t('register.alert_message_requesting'),
      tos: '',
    };
  },
  computed: {
    getName() {
      return this.$t('register.name_label');
    },
    getAge() {
      return this.$t('register.age_label');
    },
    getEmail() {
      return this.$t('register.email_label');
    },
    getPassword() {
      return this.$t('register.password_label');
    },
    getConfirmPassword() {
      return this.$t('register.comfirm_password');
    },
  },
  methods: {
    async register(values) {
      this.reg_show_alert = true;
      this.reg_in_submission = true;
      this.reg_submit_variant = 'bg-gray-600';
      this.reg_alert_variant = 'bg-blue-500';
      this.reg_alert_msg = this.$t('register.alert_message_requesting');

      try {
        await this.$store.dispatch('register', values);
      } catch (error) {
        this.reg_in_submission = false;
        this.reg_submit_variant = 'bg-purple-600 hover:bg-purple-700';
        this.reg_alert_variant = 'bg-red-500';
        // eslint-disable-next-line operator-linebreak
        this.reg_alert_msg = this.$t('register.alert_message_fail');
        return;
      }

      this.reg_alert_variant = 'bg-green-500';
      this.reg_alert_msg = this.$t('register.alert_message_success');
      window.location.reload();
    },
    openTOS() {
      ElMessageBox.confirm(
        this.$t('register.TOSinfo'),
        this.$t('register.TOS'),
        {
          confirmButtonText: this.$t('register.TOSconfirm'),
          cancelButtonText: this.$t('register.TOScancel'),
          dangerouslyUseHTMLString: true,
        },
      )
        .then(() => {
          this.tos = '1';
        })
        .catch(() => {
          this.tos = '';
        });
    },
  },
};
</script>
