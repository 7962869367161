export default {
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的账户"])}
  },
  "header": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注销"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的音乐"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
    "userCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户中心"])}
  },
  "home": {
    "article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["假如我是一只鸟，我也应该用嘶哑的喉咙歌唱：这被暴风雨所打击着的土地，这永远汹涌着我们的悲愤的河流，这无止息地吹刮着的激怒的风，和那来自林间的无比温柔的黎明……——然后我死了，连羽毛也腐烂在土地里面。为什么我的眼里常含泪水？因为我对这土地爱得深沉……"])},
    "songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音乐"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来听好音乐!"])},
    "order_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音乐名"])},
    "order_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布时间"])},
    "order_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论数"])},
    "order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序:"])}
  },
  "login": {
    "alert_message_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录失败"])},
    "alert_message_requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录中,请稍候"])},
    "alert_message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录成功!"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])}
  },
  "manage": {
    "card_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的音乐"])}
  },
  "modified": {
    "alert_message_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发生了未知错误,请稍后再试"])},
    "alert_message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改成功"])},
    "alert_message_updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请稍候,歌曲信息正在上传中"])},
    "alert_message_requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在修改中,请稍候"])},
    "genre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["流派"])},
    "getback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
    "songname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音乐名"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改"])}
  },
  "register": {
    "TOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务条款"])},
    "accept": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["我同意PK音乐的 ", _interpolate(_list(0))])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年龄"])},
    "age_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年龄"])},
    "alert_message_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发生了未知错误,请稍后重试"])},
    "alert_message_requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在注册中,请稍候"])},
    "alert_message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的账号已经创建成功!"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
    "comfirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所在地"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱"])},
    "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
    "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "password_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
    "TOSinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p style='text-align: center;'>PKMusic服务协议</p><p>欢迎您使用PKMusic的服务！</p><p><strong>&nbsp; &nbsp; &nbsp; 为使用PKMusic的服务，您应当阅读并遵守《PKMusic服务协议》（以下简称“本协议”）和《PKMusic隐私政策》。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制PKMusic责任的条款、对用户权利进行限制的条款、约定争议解决方式和司法管辖的条款等，以及开通或使用某项服务的单独协议或规则。限制、免责条款或者其他涉及您重大权益的条款可能以加粗、加下划线等形式提示您重点注意。</strong></p><p><strong>&nbsp; &nbsp; &nbsp; &nbsp;除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权使用PKMusic服务。您点击“同意”或“下一步”，或您使用PKMusic服务，或者以其他任何明示或者默示方式表示接受本协议的，均视为您已阅读并同意签署本协议。本协议即在您与PKMusic之间产生法律效力，成为对双方均具有约束力的法律文件。</strong></p><p><strong>&nbsp; &nbsp; &nbsp; &nbsp;如果您因年龄、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称&quot;监护人&quot;）的陪同下阅读和判断是否同意本协议，并特别注意未成年人使用条款。</strong></p><p><strong>&nbsp; &nbsp; &nbsp; &nbsp;如果您是中国大陆地区以外的用户，您订立或履行本协议还需要同时遵守您所属和/或所处国家或地区的法律。&nbsp;</strong></p>"])},
    "TOSconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意"])},
    "TOScancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])}
  },
  "song": {
    "alert_message_requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论发送中,请稍候"])},
    "alert_message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的评论已经成功发送!"])},
    "comment_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["无评论"]), _normalize([_interpolate(_named("count")), " 条评论"])])},
    "form_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填写您的评论..."])},
    "sort_option_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新评论"])},
    "sort_option_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最旧评论"])}
  },
  "songs": {
    "form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填写评论"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])}
  },
  "upload": {
    "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["把文件拖到此处以上传"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传"])}
  },
  "user": {
    "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户资料"])},
    "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑用户信息"])},
    "edit_pass_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新密码"])},
    "info": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年龄"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所在地"])},
      "userTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
      "adminTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理员"])},
      "superTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站长"])}
    }
  }
}