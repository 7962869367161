export default {
  "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zh_CN"])},
  "messages": {
    "_default": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "填写的不符合规定"])},
    "alpha_spaces": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "只能包含字母字符和空格"])},
    "country_excluded:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于限制，我们不接受来自此位置的用户。"])},
    "email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您填写的", _interpolate(_named("field")), "不是一个有效的邮箱"])},
    "excluded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "不是一个有效值"])},
    "max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "不能超过0:", _interpolate(_named("length")), "个字符"])},
    "max_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "必须小于或等于0:", _interpolate(_named("max"))])},
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "必须至少有0:", _interpolate(_named("length")), "个字符"])},
    "min_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), "必须大于或等于0:", _interpolate(_named("min"))])},
    "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两次密码输入不匹配"])},
    "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您必须填写", _interpolate(_named("field"))])},
    "tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你必须同意以上服务条款."])}
  },
  "song": {
    "alert_message_requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "alert_message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  }
}