import {
  Form as VeeFrom,
  Field as VeeField,
  defineRule,
  ErrorMessage,
  configure,
} from 'vee-validate';
import {
  required,
  min,
  max,
  email,
  confirmed,
  min_value as minVal,
  max_value as maxVal,
  alpha_spaces as alphaSpaces,
  not_one_of as excluded,
} from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import zh from '@vee-validate/i18n/dist/locale/zh_CN.json';
import en from '@vee-validate/i18n/dist/locale/en.json';
import ru from '@vee-validate/i18n/dist/locale/ru.json';

export default {
  install(app) {
    app.component('VeeForm', VeeFrom);
    app.component('VeeField', VeeField);
    app.component('ErrorMessage', ErrorMessage);

    defineRule('required', required);
    defineRule('tos', required);
    defineRule('min', min);
    defineRule('max', max);
    defineRule('alpha_spaces', alphaSpaces);
    defineRule('email', email);
    defineRule('max_value', maxVal);
    defineRule('min_value', minVal);
    defineRule('password_mismatch', confirmed);
    defineRule('excluded', excluded);
    defineRule('country_excluded', excluded);

    configure({
      // generateMessage: (ctx) => {
      //   const messages = {
      //     required: `The field ${ctx.field} is required.`,
      //     min: `The field ${ctx.field} is too short.`,
      //     max: `The field ${ctx.field} is too long.`,
      //     alpha_spaces: `The field ${ctx.field} may only contain
      // alphabetical characters and spaces.`,
      //     email: `The field ${ctx.field} must be a valid email.`,
      //     min_value: `The field ${ctx.field} is too low.`,
      //     max_value: `The field ${ctx.field} is too high.`,
      //     excluded: `You are not allowed to use this value for the field ${ctx.field}.`,
      //     country_excluded:
      //       'Due to restrctions, we do not accept users from this location.',
      //     password_mismatch: "The passwords don't match",
      //     tos: 'You must accept the Term of Service.',
      //   };
      //   const message = messages[ctx.rule.name]
      //     ? messages[ctx.rule.name]
      //     : `The field ${ctx.field} is invalid.`;

      //   return message;
      // },
      generateMessage: localize({
        zh,
        en,
        ru,
      }),
      validateOnBlur: true,
      validateOnChange: true,
      validateOnInput: false,
      validateOnModelUpdate: true,
    });
  },
};
