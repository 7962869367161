export default {
  "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ru"])},
  "messages": {
    "_default": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("field")), " is not valid"])},
    "alpha_spaces": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("field")), " может содержать только буквы и пробелы"])},
    "country_excluded:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Из-за ограничений мы не принимаем пользователей из этого места."])},
    "email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("field")), " должно быть действительным электронным адресом"])},
    "excluded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("field")), " должно быть допустимым значением"])},
    "max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("field")), " не может быть более 0:", _interpolate(_named("length")), " символов"])},
    "max_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("field")), " должно быть 0:", _interpolate(_named("max")), " или менее"])},
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("field")), " должно быть не менее 0:", _interpolate(_named("length")), " символов"])},
    "min_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("field")), " должно быть 0:", _interpolate(_named("min")), " или больше"])},
    "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли не совпадают"])},
    "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле ", _interpolate(_named("field")), " обязательно для заполнения"])},
    "tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы должны принять Условия обслуживания."])}
  },
  "song": {
    "alert_message_requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "alert_message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  }
}