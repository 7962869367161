<template>
  <!-- Header -->
  <header id="header" class="bg-gray-700">
    <nav
      class="container 2xl:max-w-screen-xl mx-auto flex justify-start items-center py-5 px-4"
    >
      <!-- App Name -->
      <router-link
        class="text-white font-bold uppercase text-2xl mr-4"
        :to="{ name: 'home' }"
        exact-active-class="no-active"
      >
        <img src="@/assets/PK.png" style="height: 40px" alt="" />
      </router-link>
      <!-- <router-link
        class="text-white font-bold uppercase text-2xl mr-4"
        :to="{ name: 'home' }"
        exact-active-class="no-active"
      >
        <p class="">PKMusic</p>
      </router-link> -->

      <div class="flex flex-grow items-center">
        <!-- Primary Navigation -->
        <ul class="flex flex-row mt-1">
          <!-- Navigation Links -->
          <li>
            <router-link class="px-2 text-white" :to="{ name: 'about' }">
              {{ $t('header.about') }}</router-link
            >
          </li>
          <li v-if="!userLoggedIn">
            <a
              class="px-2 text-white"
              href="#"
              @click.prevent="toggleAuthModal"
            >
              {{ $t('header.login') }} / {{ $t('header.register') }}
            </a>
          </li>
          <template v-else>
            <li>
              <router-link class="px-2 text-white" :to="{ name: 'manage' }">
                {{ $t('header.manage') }}
              </router-link>
            </li>
            <li>
              <router-link class="px-2 text-white" :to="{ name: 'userCenter' }">
                {{ $t('header.userCenter') }}
              </router-link>
            </li>
            <li class="px-2 text-white" v-if="userPermission > 0">
              <a href="#" @click.prevent="adminEnd">管理端</a>
            </li>
            <li>
              <a class="px-2 text-white" href="#" @click.prevent="signout">{{
                $t('header.logout')
              }}</a>
            </li>
          </template>
        </ul>
        <ul class="flex flex-row mt-1 ml-auto">
          <li>
            <select
              class="block w-full py-1 px-4 bg-gray-700 outline-none text-white"
              v-model="$root.$i18n.locale"
              @change="changeLocale"
            >
              <option
                :value="location.code"
                v-for="(location, i) in locations"
                :key="`Lang${i}`"
              >
                {{ location.language }}
              </option>
            </select>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { setLocale } from '@vee-validate/i18n';

export default {
  name: 'Header',
  inject: ['reload'],
  data() {
    return {
      locations: [
        { code: 'zh', language: '简体中文' },
        { code: 'en', language: 'English' },
        { code: 'ru', language: 'Pусский' },
      ],
      userPermission: 0,
    };
  },
  computed: {
    ...mapState({ userLoggedIn: (state) => state.auth.userLoggedIn }),
    ...mapState({ isInHomepage: (state) => state.auth.isInHomePage }),
  },
  methods: {
    ...mapMutations(['toggleAuthModal']),
    signout() {
      this.$store.dispatch('signout', {
        router: this.$router,
        route: this.$route,
      });

      if (this.$route.meta.requiresAuth) {
        this.$router.push({ name: 'home' });
      }
    },
    changeLocale() {
      if (this.isInHomepage) {
        this.reload();
      }
      setLocale(this.$root.$i18n.locale);
    },
    adminEnd() {
      window.location.replace('http://admin.pkmusic.pumgking.top/');
    },
  },
  created() {
    setLocale('zh');
    this.userPermission = localStorage.getItem('userPermission');
  },
};
</script>
