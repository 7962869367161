<template>
  <div>
    <app-header />

    <router-view v-if="isRouterAlive" v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>

    <app-player />

    <auth-modal />
  </div>
</template>

<style>
.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: all 0.5s linear;
}
.fade-leave-to {
  transition: all 0.5s linear;
  opacity: 0;
}
</style>

<script>
import AppHeader from './components/Header.vue';
import AuthModal from './components/Auth.vue';
import AppPlayer from './components/Player.vue';

export default {
  name: 'App',
  data() {
    return {
      isRouterAlive: true,
    };
  },
  components: {
    AppHeader,
    AuthModal,
    AppPlayer,
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
  created() {
    this.$store.dispatch('init_login');
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
};
</script>
